<template>
  <div class="box">
    <headerNav title="任务中心" :leftIcon="true" @leftClick="back" rightNav="模板库" rightIconSvg="a2" rightIconClass="iconClass"
      @rightClick="toTemplaterLibrary" />
    <div :class="hasRolePermission('mission:center:create-edit') ? 'mission-box' : 'mission-box mission-box-l'">
      <ul class="table-head">
        <li style="flex: 0.5;">市场</li>
        <li style="justify-content: flex-end;padding-right:3px">转单产值</li>
        <li style="justify-content: flex-end;padding-right:3px">广告费用</li>
        <li style="flex: 1.1;">广告费用比例</li>
        <li @click="openSearchPopup">
          <img :src="require('@/assets/a6.svg')" class="search-icon" alt="">
        </li>
      </ul>
      <pullRefresh ref="pullRefresh" @refresh="onRefresh">
        <van-list v-if="list.length" class="vant-list-style" v-model="loading" :finished="finished" @load="onLoad">
          <div v-for="item in list" :key="item.taskPeriod" class="list-item">
            <p>{{ item.taskPeriod }}</p>
            <ul v-for="item2 in item.profitTaskDTOs" @click="toMissionDetails(item2.id)">
              <li style="flex: 0.5;">{{ item2.marketName  }}</li>
              <li style="font-family: Bahnschrift;justify-content: flex-end;">{{ item2.transferValue.toFixed(2)  }}万</li>
              <li style="font-family: Bahnschrift;justify-content: flex-end;">{{ item2.costFee.toFixed(2)  }}万</li>
              <li style="flex: 1.1;font-family: Bahnschrift;justify-content: flex-end;">{{ item2.costScale.toFixed(2)  }}%</li>
              <li style="flex: 0.4;"></li>
            </ul>
          </div>
        </van-list>
        <div v-else class="empty-box">
          <img :src="require('@/assets/a-empty.png')" alt="">
          <div>暂无数据</div>
        </div>
      </pullRefresh>

    </div>
    <van-popup v-model="searchPopup" position="top" style="border-radius: 0 0 8px 8px;">
      <div class="down-content">
        <p>月份</p>
        <div class="mark-box">
          <van-field v-model="month" name="选择月份" placeholder="请选择月" @click="openChooseDate" clickable readonly
            class="choose-input">
            <template v-slot:right-icon>
              <div style="display: flex;align-items: center;">
                <img :src="require('@/assets/a7.svg')" class="search-icon" alt="">
              </div>
            </template>
          </van-field>
          <!-- 选择月份 -->
          <van-popup v-model="showChooseDate" position="bottom" :style="{ height: '45%' }">
            <van-picker ref="datePicker" show-toolbar :columns="dateColumns" @confirm="onConfirmChooseDate"
              cancel-button-text=" " title="选择月份" @cancel="showChooseDate = false" />
          </van-popup>
        </div>
        <p>市场<span>（多选）</span></p>
        <ul class="market-box">
          <li v-for="item in markenList" :class="item.checked ? 'item-checked' : ''" :key="item.code"
            @click="itemClick(item)">
            {{ item.name }}
            <img v-if="item.checked" :src="require('@/assets/a4.svg')" alt="">
          </li>
        </ul>
        <div class="bottom-btn">
          <div @click="reset">重置</div>
          <div @click="submit">确认</div>
        </div>
      </div>
    </van-popup>
    <div class="create-btn" v-if="hasRolePermission('mission:center:create-edit')">
      <van-button block type="info" color="#3975C6" @click="toMissionDetails('0')">创建任务</van-button>
    </div>
  </div>
</template>

<script>
import api from '../../../api/api'
import moment from 'moment';
import { hasRolePermission } from '../../../hooks/usePermission';

export default {
  data () {
    return {
      list: [],
      finished: false,
      loading: false,
      pageNum: 1, // 分页
      pageSize: 3, // 每页条数
      total: 0, // 数据总条数
      searchPopup: false,
      markenList: [],
      chooseMarkets: [],
      taskPeriod: '',
      showChooseDate: false,
      dateColumns: [],
      year: '',
      month: '',
    }
  },
  methods: {
    //返回
    back () {
      this.$router.push({ name: 'TaskAdvcost' })
    },
    //前往模板库
    toTemplaterLibrary () {
      this.$router.push({ name: 'TaskAdvcostTemplateLibrary' })
    },
    //前往任务详情
    toMissionDetails (id) {
      if (this.hasRolePermission('mission:center:create-edit')) {
        this.$router.push({ name: 'TaskAdvcostMissionDetails', params: { id: id } })

      }
    },
    //获取任务列表
    async getMissionList () {
      let obj = {
        page: this.pageNum,    //当前页
        limit: this.pageSize,     //默认每月显示三个月的数据
        queryStr: JSON.stringify({
          Q_taskPeriod_EQ: this.taskPeriod,
          Q_marketCode_IN: this.chooseMarkets.join(',')
        })
      }
      let res = await api.getMissionList(obj)
      if (res.code == 200) {
        if (!res.data || res.data?.length === 0) { // 判断获取数据条数若等于0
          // this.list = []; // 清空数组
          this.finished = true; // 停止加载
        } else {
          // 如果list长度大于等于总数据条数,数据全部加载完成
          if (this.list.length >= this.list.concat(res.data)) {
            this.finished = true; // 结束加载状态
          }
          if (this.taskPeriod) {
            this.finished = true
          }
          // 若数据条数不等于0
          res.data && this.list.push(...res.data) // 将数据放入list中
          this.loading = false; // 加载状态结束 
        }
        if (this.$refs.pullRefresh) {
          this.$refs.pullRefresh.refreshSuccess()
        }
      }
    },
    openSearchPopup () {
      this.searchPopup = !this.searchPopup

    },
    //搜索重置
    reset () {
      this.markenList.forEach((e => this.$set(e, 'checked', false)))
      this.chooseMarkets = []
      this.year = '';
      this.month = '';
      this.taskPeriod = ''
      this.onRefresh()
      this.searchPopup = false
    },
    //搜索确认
    submit () {
      this.chooseMarkets = []
      this.markenList.forEach((e => {
        if (e.checked) {
          this.chooseMarkets.push(e.code)
        }
      }))
      this.taskPeriod = this.year?this.year + '-' + this.month:''
      this.onRefresh()
      this.searchPopup = false
    },
    // 获取市场
    async getMarketCode () {
      let params = {
        Q_typeCode_IN: 'DICT771486',
      }
      let res = await api.getDictionariesList(params)
      res.data.forEach(e => {
        if (e.status != 1) {
          this.markenList.push(e)
        }
      })
    },
    //市场选中事件
    itemClick (item) {
      this.$set(item, 'checked', !item.checked);
    },
    //打开选择月份弹窗
    openChooseDate () {
      this.showChooseDate = true
      this.$nextTick(() => {
        this.$refs.datePicker.setValues([moment().year(), moment().month() + 1 < 10 ? '0' + (moment().month() + 1) : (moment().month() + 1) + ''])
      })
    },
    //选择月份
    onConfirmChooseDate (value) {
      this.year = value[0]
      this.month = value[1]
      this.showChooseDate = false
    },
    onRefresh (e) {
      this.finished = false; // 清空列表数据
      this.loading = true; // 将 loading 设置为 true，表示处于加载状态
      this.pageNum = 1; // 分页数赋值为1
      this.list = []; // 清空数组
      this.onLoad()
    },
    onLoad (e) { // 若加载条到了底部
      let timer = setTimeout(() => { // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
        // this.getList(); // 调用上面方法,请求数据
        this.getMissionList(e)
        this.pageNum++; // 
        this.finished && clearTimeout(timer); //清除计时器
      }, 100);
    },
  },
  mounted () { },
  created () {
    let nowYear = moment().year()
    for (let i = nowYear - 10; i < nowYear + 10; i++) {
      let obj = {
        text: i,
        children: []
      }
      for (let i2 = 1; i2 < 13; i2++) {
        obj.children.push({
          text: i2 < 10 ? '0' + i2 : i2 + ''
        })
      }
      this.dateColumns.push(obj)
    }
    // this.getMissionList()
    this.onLoad()
    this.getMarketCode()

  }
}
</script>

<style scoped lang="scss">
.box {
  background-color: #F3F5F7;
  height: 100vh;
}

.create-btn {
  padding: 11px 19px;
  font-size: 18px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  background: #FFFFFF;
  box-shadow: 0px -1 8px 0px rgba(0, 0, 0, 0.04);
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;
  height: 90px;

  .van-button {
    width: 100%;
    border-radius: 4px;
    font-size: 18px;
  }
}

.mission-box {
  height: calc(100vh - 150px);
  overflow: auto;
  position: relative;

  .table-head {
    display: flex;
    padding: 0 0 0 16px;
    background: #FFFFFF;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #1D2129;
    position: sticky;
    top: 0;
    z-index: 2;

    li {
      flex: 1;
      display: flex;
      height: 42px;
      align-items: center;
      justify-content: center;

      &:has(.search-icon) {
        flex: 0.4;
        border-left: 1px solid #F3F5F7;
      }
      &:last-child{
        flex: 0.4;
        border-left: 1px solid #F3F5F7;
      }
    }

    .search-icon {
      width: 15px;
    }
  }

  .list-item {

    p {
      margin: 0;
      line-height: 36px;
      font-size: 15px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      color: #333333;
      position: relative;
      text-align: left;
      padding-left: 10px;
      margin: 0 28px 0 22px;

      &::after {
        content: '';
        width: 4px;
        height: 16px;
        background: #0E8BFF;
        border-radius: 1px;
        position: absolute;
        left: 0;
        top: 0;
        margin: 10px 0;
      }
    }

    ul {
      display: flex;
      padding: 0 0 0 16px;
      background: #FFFFFF;
      font-size: 15px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #333333;
      position: sticky;
      top: 0;

      li {
        flex: 1;
        display: flex;
        height: 42px;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgba(51, 51, 51, 0.05);
        // padding: 0 10px;
      }
    }

  }
}

.mission-box-l {
  height: calc(100vh - 50px);

}

.empty-box {
  img {
    width: 100%;
    margin-top: 50%;
  }

  div {
    font-size: 15px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #5E5E5E;

  }
}

::v-deep {
  .iconClass {
    margin-bottom: -2px;
    color: red;
    margin-right: 2px;
  }
}

.down-content {
  padding-top: 50px;
  background: #FFFFFF;
  overflow: hidden;

  &>p {
    margin: 0;
    text-align: left;
    line-height: 40px;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #000000;
    padding: 0 15px;

    span {
      color: #999999;
    }
  }
}

.market-box {
  display: flex;
  flex-wrap: wrap;
  gap: 17px 8px;
  padding: 0 15px;
  padding-bottom: 20px;


  li {
    width: 23%;
    height: 35px;
    background: #F6F6F6;
    border-radius: 4px;
    line-height: 35px;
    text-align: center;
    background: #F6F6F6;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #333333;
  }

  .item-checked {
    background-color: #E8F1FF;
    color: #0E8BFF;
    position: relative;

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 21px;
    }
  }
}

.bottom-btn {
  display: flex;
  border-top: 1px solid #EEEEEE;

  div {
    flex: 1;
    font-size: 19px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    text-align: center;
    line-height: 63px;

    &:first-child {
      color: #333333;
      border-right: 1px solid #EEEEEE;
    }

    &:last-child {
      color: #3DA2FF;
    }
  }
}

.search-icon {
  width: 15px;
}

.choose-input {
  background: #F8F8F8;
  font-size: 17px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #999999;
}

.mark-box:has(.choose-input) {
  padding: 5px 15px;
}
</style>